// Images file for 'med2.insurancespecialists.com'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import NextArrow from './assets/grey-backarrow.png';
import NextArrowWhite from '../../../../src/images/next-arrow-white.png';
import Background from './assets/back-ground.jpg';
import LineArtCouple from './assets/lineArtCouple.png';
import LineArtLandscape from './assets/lineArtCoupleLandscape.png';

export default {
  NextArrowWhite,
  Favicon,
  Dropdown,
  NextArrow,
  Background,
  LineArtCouple,
  LineArtLandscape,
};
